import { pricing_strategy_constraint_type } from "@prisma/client";
import { IconPlus } from "@tabler/icons-react";
import { InfoIcon } from "lucide-react";
import { useState } from "react";
import { GetCompetitorBrandNamesQueryResponseType } from "src/backend/internal-api/queries/getCompetitorBrandNamesQuery";
import Col from "src/frontend/components/Col";
import LightLabel from "src/frontend/components/LightLabel";
import Row from "src/frontend/components/Row";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import Dialog from "src/frontend/components/ui/Dialog";
import FancyBox, { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import FancyBoxSelectedItemsList from "src/frontend/components/ui/FancyBoxSelectedItemsList";
import NumberInput from "src/frontend/components/ui/NumberInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useGetCompetitorBrandNamesQuery from "src/frontend/hooks/queries/useGetCompetitorBrandNamesQuery";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import useFlags from "src/frontend/hooks/useFlags";
import ConstraintBoundsVisualization from "src/frontend/pages/company/rules/ConstraintBoundsVisualization";
import usePricingRulesGroupConstraintStore from "src/frontend/stores/usePricingRulesGroupConstraintStore";
import getPriceBoundTypeSymbol from "src/frontend/utils/constraints/getPriceBoundTypeSymbol";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  MatchTypeType,
  PricingConstraintType,
} from "src/shared/trpc/common/PricingConstraint";
import {
  ROUNDING_CONSTRAINT_PRICE_ENDING_VALUES,
  RoundingConstraintPriceEndingType,
} from "src/shared/trpc/common/RoundingConstraintPriceEnding";
import {
  CompetitorPriceComparisonType,
  CompetitorPriceComparisonTypeVariants,
} from "src/shared/trpc/common/enum/CompetitorPriceComparisonType";
import {
  CompetitorPriceLookbackPeriodType,
  CompetitorPriceLookbackPeriodVariants,
} from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { ConstraintType } from "src/shared/trpc/common/enum/ConstraintType";
import {
  PriceBoundType,
  PriceBoundTypeVariants,
} from "src/shared/trpc/common/enum/PriceBoundType";
import { PriceComparisonTargetType } from "src/shared/trpc/common/enum/PriceComparisonTargetType";
import {
  PriceTargetType,
  PriceTargetTypeVariants,
} from "src/shared/trpc/common/enum/PriceTargetType";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import pricingConstraintIncludePriceBounds from "src/shared/utils/constraints/pricingConstraintIncludePriceBounds";
import shouldIncludeConstraintInMinMaxCalculations from "src/shared/utils/constraints/shouldIncludeConstraintInMinMaxCalculations";
import noop from "src/shared/utils/noop";
import deepEquals from "src/shared/utils/objects/deepEquals";
import getConstraintErrorMessage from "src/shared/validation/getConstraintErrorMessage";
import isPriceComparisonConstraintValid from "src/shared/validation/isPriceComparisonConstraintValid";
import isPricingConstraintConstraintValid from "src/shared/validation/isPricingConstraintConstraintValid";
import isRoundingConstraintValid from "src/shared/validation/isRoundingConstraintValid";

function competitorStringToFancyBoxItem(competitor: string): FancyBoxItem {
  return {
    id: competitor,
    label: competitor,
    value: competitor,
  };
}

function competitorToFancyBoxItem(
  competitor: GetCompetitorBrandNamesQueryResponseType,
): FancyBoxItem {
  return {
    id: competitor.id,
    label: competitor.competitor_name,
    value: competitor.competitor_name,
  };
}

export function convertCompetitorMatchType(matchType: MatchTypeType): {
  label: keyof typeof COMPETITOR_PRICE_MATCH_TYPE_OPTIONS;
  value: MatchTypeType;
} | null {
  for (const [key, value] of Object.entries(
    COMPETITOR_PRICE_MATCH_TYPE_OPTIONS,
  )) {
    if (deepEquals(value, matchType)) {
      return {
        label: key,
        value,
      };
    }
  }

  return null;
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const COMPETITOR_PRICE_MATCH_TYPE_OPTIONS: Record<
  string,
  MatchTypeType
> = Object.freeze({
  "Exact Match": {
    brand_match: true,
    flavor_match: true,
    organic_match: true,
    size_match: true,
    variety_match: true,
  },

  "Different brand": {
    brand_match: false,
    flavor_match: null,
    organic_match: null,
    size_match: null,
    variety_match: null,
  },

  "Different size": {
    brand_match: true,
    flavor_match: true,
    organic_match: true,
    size_match: false,
    variety_match: true,
  },

  "Different flavor": {
    brand_match: true,
    flavor_match: false,
    organic_match: true,
    size_match: true,
    variety_match: true,
  },

  "Different flavor and size": {
    brand_match: true,
    flavor_match: false,
    organic_match: null,
    size_match: false,
    variety_match: true,
  },
});
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const CONSTRAINT_TYPE_OPTIONS: ConstraintType[] = [
  "MARGIN",
  "AVERAGE_MARGIN",
  "PRICE_CHANGE",
  "COMPETITOR_PRICE",
  "ROUNDING",
  "PRICE_COMPARISON",
];

type ConstraintDialogProps = {
  constraintToEdit: MaybeNull<PricingConstraintType>;
  disabled: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (data: PricingConstraintType) => void;
  open: boolean;
};

export default function ConstraintDialog({
  constraintToEdit,
  disabled,
  onOpenChange,
  onSave,
  open,
}: ConstraintDialogProps) {
  const [showErrors, setShowErrors] = useState(false);
  const state = usePricingRulesGroupConstraintStore();
  const pricingStrategyQuery = useGetPricingStrategyByIdQuery();
  const competitorBrandNamesQuery = useGetCompetitorBrandNamesQuery();
  const competitorOptions = competitorBrandNamesQuery.data ?? [];
  const competitorOptionsExist =
    !competitorBrandNamesQuery.isLoading && arrayNotEmpty(competitorOptions);

  const strategy = pricingStrategyQuery.data;

  const isEditing = constraintToEdit != null;
  const title = `${!isEditing ? "Add New " : "Edit "} Rule`;

  const selectedItems = filterEmptyValues(
    state.competitors.map((value) => {
      const item = competitorOptions.find(
        (opt) => opt.competitor_name === value,
      );
      if (item == null) {
        return null;
      }
      return competitorToFancyBoxItem(item);
    }),
  );

  const modelingPrice = PriceTargetTypeVariants.find(
    (val) => val === strategy?.objective?.modeling_price,
  );

  const impactPriceTargetOptions = PriceTargetTypeVariants;
  const priceComparisonTargetOptions: PriceComparisonTargetType[] =
    filterEmptyValues([modelingPrice, "PRE_LUCA_PRICE"]);

  const { partialMatchSelectorEnabled } = useFlags();

  return (
    <Dialog
      confirmTitle="Save"
      dialogTitle={title}
      maxWidth={650}
      onConfirm={() => {
        const constraint = state.convertInputConstraint();
        if (!isPricingConstraintConstraintValid(constraint, modelingPrice)) {
          setShowErrors(true);
          return;
        }

        onSave(constraint);
        onOpenChange(false);
      }}
      onOpenChange={(open) => {
        setShowErrors(false);
        onOpenChange(open);
      }}
      open={open}
      trigger={
        <Button
          disabled={disabled || modelingPrice == null}
          onClick={() => {
            if (modelingPrice != null) {
              usePricingRulesGroupConstraintStore.setState({
                price_target_type: modelingPrice,
              });
            }
          }}
          variant="basic"
        >
          <IconPlus className="pr-2" />
          Add Rule
        </Button>
      }
    >
      <div>
        <div>
          <LightLabel className="mb-[2px]">Rule Type</LightLabel>
          <Select
            onValueChange={(value) => {
              const type = value as ConstraintType;
              usePricingRulesGroupConstraintStore.setState({ type });
              if (type === "PRICE_COMPARISON") {
                const nextImpactedPriceOptions = PriceTargetTypeVariants.filter(
                  (val) => type !== "PRICE_COMPARISON" || val !== modelingPrice,
                );

                if (state.price_target_type === modelingPrice) {
                  usePricingRulesGroupConstraintStore.setState({
                    price_target_type: nextImpactedPriceOptions[0],
                  });
                }

                usePricingRulesGroupConstraintStore.setState({
                  comparison_price_target: priceComparisonTargetOptions[0],
                });
              }
              if (type === "AVERAGE_MARGIN") {
                usePricingRulesGroupConstraintStore.setState({
                  bound_type: "PERCENT",
                });
              }
            }}
            value={state.type}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select rule type" />
            </SelectTrigger>
            <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
              {CONSTRAINT_TYPE_OPTIONS.map((key) => {
                return (
                  <SelectItem key={key} value={key}>
                    {formatGenericEnumLabel(key)}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          {showErrors && state.type == null && (
            <ErrorLabel>Rule type is required</ErrorLabel>
          )}
        </div>
        {state.type === pricing_strategy_constraint_type.AVERAGE_MARGIN && (
          <div className="mt-4 rounded-md bg-blue-100 p-4 text-sm text-blue-500">
            <p>
              This is the average margin across selected skus based on Luca
              forecasts and elasticities.
            </p>
          </div>
        )}

        {partialMatchSelectorEnabled &&
          state.type === pricing_strategy_constraint_type.COMPETITOR_PRICE && (
            <div className="mt-2">
              <LightLabel className="mb-[2px]">Match Types</LightLabel>
              <FancyBox
                disableCreateNew
                disableEditing
                disableSearch
                disableSelectedItemsDisplay
                disabled={!competitorOptionsExist}
                height={350}
                items={Object.entries(COMPETITOR_PRICE_MATCH_TYPE_OPTIONS).map(
                  ([key, _]) => competitorStringToFancyBoxItem(key),
                )}
                selectTitle="Select"
                selectedItems={filterEmptyValues(
                  state.pricing_constraint_competitor_match_types.map(
                    ({ label }) =>
                      COMPETITOR_PRICE_MATCH_TYPE_OPTIONS[label]
                        ? competitorStringToFancyBoxItem(label)
                        : null,
                  ),
                )}
                setItems={noop}
                setSelectedItems={noop}
                setSelectedItemsOverrideFn={(item) => {
                  const values =
                    state.pricing_constraint_competitor_match_types;
                  let newValues = [...values];
                  if (values.some((val) => val.label === item.label)) {
                    newValues = newValues.filter(
                      (val) => val.label !== item.label,
                    );
                  } else {
                    if (item.label in COMPETITOR_PRICE_MATCH_TYPE_OPTIONS) {
                      newValues.push({
                        label: item.label,
                        value: COMPETITOR_PRICE_MATCH_TYPE_OPTIONS[item.label],
                      });
                    }
                  }

                  usePricingRulesGroupConstraintStore.setState({
                    pricing_constraint_competitor_match_types: newValues,
                  });
                }}
                width={590}
              />
              {showErrors &&
                (state.pricing_constraint_competitor_match_types == null ||
                  state.pricing_constraint_competitor_match_types.length ===
                    0) && <ErrorLabel>Match Type is required</ErrorLabel>}
            </div>
          )}
        <div className="mt-2">
          <LightLabel className="mb-[2px]">Impacted Price</LightLabel>
          <Select
            onValueChange={(value) => {
              const price_target_type = value as PriceTargetType;
              usePricingRulesGroupConstraintStore.setState({
                price_target_type,
              });
            }}
            value={state.price_target_type}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select impacted price" />
            </SelectTrigger>
            <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
              {impactPriceTargetOptions.map((key) => {
                if (key === "END_CUSTOMER_PRICE") {
                  // END_CUSTOMER_PRICE is not a valid option for the rules constraints engine
                  return null;
                }
                return (
                  <SelectItem key={key} value={key}>
                    {formatGenericEnumLabel(key)}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          {showErrors && state.price_target_type == null && (
            <ErrorLabel>Impacted price is required</ErrorLabel>
          )}
        </div>
        {state.type === "PRICE_COMPARISON" && modelingPrice != null && (
          <div className="mt-2">
            <Row className="mb-1">
              <LightLabel>Comparison Price</LightLabel>
              <Tooltip
                content={
                  <p className="w-[300px]">
                    The comparison price can only be the modeling price or the
                    pre-Luca product price.
                  </p>
                }
                side="right"
              >
                <InfoIcon className="ml-2" size={14} />
              </Tooltip>
            </Row>
            <Select
              onValueChange={(value) => {
                const comparison_price_target =
                  value as PriceComparisonTargetType;
                usePricingRulesGroupConstraintStore.setState({
                  comparison_price_target,
                });
              }}
              value={state.comparison_price_target ?? undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select comparison price" />
              </SelectTrigger>
              <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
                {priceComparisonTargetOptions.map((key) => {
                  return (
                    <SelectItem key={key} value={key}>
                      {formatGenericEnumLabel(key)}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {showErrors && state.comparison_price_target == null && (
              <ErrorLabel>Comparison price is required</ErrorLabel>
            )}
            {showErrors &&
              state.comparison_price_target != null &&
              isPriceComparisonConstraintValid(
                state.convertInputConstraint(),
                modelingPrice,
              ) && (
                <ErrorLabel>
                  If the impacted price is set to the modeling price the
                  comparison price must be the pre-Luca price
                </ErrorLabel>
              )}
          </div>
        )}
        {state.type === "COMPETITOR_PRICE" && (
          <div className="mt-2">
            <LightLabel className="mb-[2px]">
              Competitor Comparison Price
            </LightLabel>
            <Select
              onValueChange={(value) => {
                const competitor_price_target_type = value as PriceTargetType;
                usePricingRulesGroupConstraintStore.setState({
                  competitor_price_target_type,
                });
              }}
              value={state.competitor_price_target_type ?? undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select competitor price" />
              </SelectTrigger>
              <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
                {PriceTargetTypeVariants.map((key) => {
                  return (
                    <SelectItem key={key} value={key}>
                      {formatGenericEnumLabel(key)}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            {showErrors && state.competitor_price_target_type == null && (
              <ErrorLabel>Competitor target price is required</ErrorLabel>
            )}
            <div className="mt-2">
              <LightLabel className="mb-[2px]">Competitors</LightLabel>
              <FancyBox
                disableCreateNew
                disableEditing
                disableSearch
                disableSelectedItemsDisplay
                disabled={!competitorOptionsExist}
                height={350}
                items={competitorOptions.map((val) =>
                  competitorToFancyBoxItem(val),
                )}
                loading={competitorBrandNamesQuery.isLoading}
                selectTitle="Select competitors"
                selectedItems={selectedItems}
                setItems={noop}
                setSelectedItems={noop}
                setSelectedItemsOverrideFn={(item) => {
                  const values = state.competitors;
                  let newValues = [];
                  if (values.includes(item.value)) {
                    newValues = values.filter((val) => val !== item.value);
                  } else {
                    newValues = values.concat(item.value);
                  }
                  usePricingRulesGroupConstraintStore.setState({
                    competitors: newValues,
                  });
                }}
                width={590}
              />
              {showErrors && arrayEmpty(state.competitors) && (
                <ErrorLabel>Competitor selection is required</ErrorLabel>
              )}
              <div className="mt-2">
                <LightLabel className="mb-[2px]">Baseline</LightLabel>
                <Select
                  disabled={!competitorOptionsExist}
                  onValueChange={(value) => {
                    usePricingRulesGroupConstraintStore.setState({
                      competitor_price_comparison_type:
                        value as CompetitorPriceComparisonType,
                    });
                  }}
                  value={state.competitor_price_comparison_type ?? undefined}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select one" />
                  </SelectTrigger>
                  <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
                    {CompetitorPriceComparisonTypeVariants.map((type) => {
                      return (
                        <SelectItem key={type} value={type}>
                          {formatGenericEnumLabel(type)}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                {showErrors &&
                  state.competitor_price_comparison_type == null && (
                    <ErrorLabel>Baseline selection is required</ErrorLabel>
                  )}
              </div>
              <div className="mt-2">
                <LightLabel className="mb-[2px]">Lookback Period</LightLabel>
                <Select
                  disabled={!competitorOptionsExist}
                  onValueChange={(value: CompetitorPriceLookbackPeriodType) => {
                    usePricingRulesGroupConstraintStore.setState({
                      competitor_price_lookback_period: value,
                    });
                  }}
                  value={state.competitor_price_lookback_period ?? undefined}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a lookback period" />
                  </SelectTrigger>
                  <SelectContent
                    onCloseAutoFocus={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {CompetitorPriceLookbackPeriodVariants.map((opt) => {
                      return (
                        <SelectItem key={opt} value={opt}>
                          {formatGenericEnumLabel(opt)}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                {showErrors &&
                  state.competitor_price_lookback_period == null && (
                    <ErrorLabel>Lookback period is required</ErrorLabel>
                  )}
              </div>
              {state.competitor_price_comparison_type != null &&
                arrayNotEmpty(state.competitors) && (
                  <Row className="mt-2">
                    <div className="mb-2">
                      <p className="whitespace-nowrap text-xs">
                        {formatGenericEnumLabel(
                          state.competitor_price_comparison_type,
                        )}
                      </p>
                    </div>
                    <FancyBoxSelectedItemsList
                      className="ml-2"
                      selectedItems={state.competitors.map((comp) =>
                        competitorStringToFancyBoxItem(comp),
                      )}
                    />
                    {state.competitor_price_lookback_period != null && (
                      <div className="mb-2">
                        <p className="whitespace-nowrap text-xs">
                          over the{" "}
                          {formatGenericEnumLabel(
                            state.competitor_price_lookback_period,
                          ).toLowerCase()}
                        </p>
                      </div>
                    )}
                  </Row>
                )}
            </div>
          </div>
        )}
        {pricingConstraintIncludePriceBounds(state.type) && (
          <div className="mt-4">
            <Row className="gap-4">
              <Col className="w-full">
                <LightLabel className="mb-[2px]">
                  New prices should be
                </LightLabel>
                <Select
                  onValueChange={(value) => {
                    usePricingRulesGroupConstraintStore.setState({
                      bound_type: value as PriceBoundType,
                    });
                  }}
                  value={
                    state.type === "AVERAGE_MARGIN"
                      ? "PERCENT"
                      : state.bound_type ?? undefined
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select bound type" />
                  </SelectTrigger>
                  <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
                    {PriceBoundTypeVariants.filter(
                      (val) =>
                        (state.type === "AVERAGE_MARGIN" &&
                          val === "PERCENT") ||
                        state.type !== "AVERAGE_MARGIN",
                    ).map((key) => {
                      const isMarginConstraint =
                        state.type === "MARGIN" ||
                        state.type === "AVERAGE_MARGIN";
                      return (
                        <SelectItem key={key} value={key}>
                          {key === "PERCENT" ? "%" : "$"}{" "}
                          {isMarginConstraint
                            ? "margin"
                            : "change from baseline"}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </Col>
              <Row className="gap-2">
                <Col>
                  <LightLabel className="mb-[2px]">Lower Bound</LightLabel>
                  <Row>
                    <NumberInput
                      className="w-36 rounded-lg border px-4 py-2"
                      onChangeValue={(val) => {
                        usePricingRulesGroupConstraintStore.setState({
                          lower_bound_input: val,
                        });
                      }}
                      placeholder="Lower bound"
                      value={state.lower_bound_input}
                    />
                    <p className="ml-1 w-4 text-sm">
                      {getPriceBoundTypeSymbol(state.bound_type)}
                    </p>
                  </Row>
                </Col>
                <Col>
                  <LightLabel className="mb-[2px]">Upper Bound</LightLabel>
                  <Row>
                    <NumberInput
                      className="w-36 rounded-lg border px-4 py-2"
                      onChangeValue={(val) => {
                        if (state.bound_type === "PERCENT") {
                          if (Number(val) > 100) {
                            return;
                          }
                        }

                        usePricingRulesGroupConstraintStore.setState({
                          upper_bound_input: val,
                        });
                      }}
                      placeholder="Upper bound"
                      value={state.upper_bound_input}
                    />
                    <p className="ml-1 w-4 text-sm">
                      {getPriceBoundTypeSymbol(state.bound_type)}
                    </p>
                  </Row>
                </Col>
              </Row>
            </Row>
            {showErrors &&
              getConstraintErrorMessage(state.convertInputConstraint()) !==
                null && (
                <ErrorLabel>
                  {getConstraintErrorMessage(state.convertInputConstraint())}
                </ErrorLabel>
              )}
          </div>
        )}
        {state.type === "ROUNDING" && (
          <div className="mt-4">
            <LightLabel className="mb-[2px]">
              I want all price endings to be
            </LightLabel>
            {ROUNDING_CONSTRAINT_PRICE_ENDING_VALUES.map(
              ({ value: ending }) => {
                const checked = state.round_to.includes(ending);
                return (
                  <Checkbox
                    checked={checked}
                    id={ending}
                    key={ending}
                    label={ending}
                    onCheckedChange={(value, checked) => {
                      const values = state.round_to;
                      let newValues = [];
                      if (checked) {
                        newValues = values.concat(
                          value as RoundingConstraintPriceEndingType,
                        );
                      } else {
                        newValues = values.filter((cur) => cur !== value);
                      }
                      usePricingRulesGroupConstraintStore.setState({
                        round_to: newValues,
                      });
                    }}
                  />
                );
              },
            )}
            {showErrors &&
              !isRoundingConstraintValid(state.convertInputConstraint()) && (
                <ErrorLabel>At least one selection is required</ErrorLabel>
              )}
          </div>
        )}
        {pricingConstraintIncludePriceBounds(state.type) && (
          <ConstraintBoundsVisualization
            boundType={state.bound_type}
            className="my-14 mb-16"
            constraintType={state.type}
            lowerBound={state.lower_bound_input}
            upperBound={state.upper_bound_input}
          />
        )}
        {shouldIncludeConstraintInMinMaxCalculations(state.type) && (
          <Row>
            <Checkbox
              checked={state.strive_to_meet}
              id="strive-to-meet-previous-rule-checkbox"
              label="Strive to meet this rule if it conflicts with more prioritized rules"
              onCheckedChange={(_, checked) => {
                usePricingRulesGroupConstraintStore.setState({
                  strive_to_meet: checked,
                });
              }}
            />
            <Tooltip
              content={
                <p className="w-[300px]">
                  In the event that this rule conflicts with more prioritized
                  rules checking this will constrain prices to be as close as
                  possible to the current rule.
                </p>
              }
            >
              <InfoIcon className="ml-2" size={14} />
            </Tooltip>
          </Row>
        )}
      </div>
    </Dialog>
  );
}
