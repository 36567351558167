import { COMPETITOR_PRICE_MATCH_TYPE_OPTIONS } from "src/frontend/pages/company/rules/ConstraintDialog";
import {
  MatchTypeType,
  PricingConstraintType,
} from "src/shared/trpc/common/PricingConstraint";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import isNumber from "src/shared/utils/numbers/isNumber";
import uuid from "src/shared/utils/uuid";
import { create } from "zustand";

type StoreState = Omit<
  PricingConstraintType,
  "price_target_type" | "pricing_constraint_competitor_match_types"
> & {
  lower_bound_input: string;
  price_target_type: MaybeUndefined<PriceTargetType>;
  pricing_constraint_competitor_match_types: {
    label: keyof typeof COMPETITOR_PRICE_MATCH_TYPE_OPTIONS;
    value: MatchTypeType;
  }[];
  upper_bound_input: string;
};

type StoreActions = {
  convertInputConstraint: () => PricingConstraintType;
  reset: () => void;
};

const defaultState: StoreState = {
  average_margin_constraint_id: null,
  bound_type: "PERCENT",
  comparison_price_calculation_error: null,
  comparison_price_target: "PRE_LUCA_PRICE",
  competitor_price_calculated: null,
  competitor_price_calculation_error: null,
  competitor_price_comparison_type: null,
  competitor_price_lookback_period: null,
  competitor_price_target_type: "LIST_PRICE",
  competitors: [],
  constraint_met: null,
  experiment_id: null,
  id: uuid(),
  lower_bound: null,
  lower_bound_calculated: null,
  lower_bound_calculation_error: null,
  lower_bound_input: "",
  lower_bound_value: null,
  price_target_type: undefined,
  pricing_constraint_competitor_match_types: [],
  pricing_rules_group_id: null,
  pricing_rules_group_name: null,
  priority: 0,
  reference_price_calculated: null,
  round_to: [],
  strive_to_meet: true,
  type: "PRICE_CHANGE",
  upper_bound: null,
  upper_bound_calculated: null,
  upper_bound_calculation_error: null,
  upper_bound_input: "",
  upper_bound_value: null,
};

function convertInputToBound(input_value: string): MaybeNumber {
  return isNumber(input_value) ? Number(input_value) : null;
}

const usePricingRulesGroupConstraintStore = create<StoreActions & StoreState>(
  (set, get) => ({
    ...defaultState,
    convertInputConstraint: () => {
      const state = get();
      const result = {
        ...state,
        lower_bound: convertInputToBound(state.lower_bound_input),
        price_target_type: state.price_target_type,
        pricing_constraint_competitor_match_types:
          state.pricing_constraint_competitor_match_types.map(
            ({ value }) => value,
          ),
        upper_bound: convertInputToBound(state.upper_bound_input),
      };
      return result as PricingConstraintType;
    },
    reset: () => set(defaultState),
  }),
);

export default usePricingRulesGroupConstraintStore;
