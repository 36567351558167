import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import PublishDraftResultsModal from "src/frontend/components/admin/PublishDraftResultsModal";
import SimpleSelect from "src/frontend/components/design-system/SimpleSelect";
import { Badge } from "src/frontend/components/ui/Badge";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useGetGroupSummaryQuery from "src/frontend/hooks/queries/useGetGroupSummaryQuery";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import MetricSummaryCards from "src/frontend/pages/pricing/PricePlanSummaryMetrics/MetricSummaryCards";
import PricePlanAdminWrapper from "src/frontend/pages/pricing/PricePlanSummaryMetrics/PricePlanAdminWrapper";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  CustomerSegmentType,
  CustomerSegmentVariants,
} from "src/shared/trpc/common/enum/CustomerSegment";
import formatDate from "src/shared/utils/dates/formatDate";
import getLiveDuration from "src/shared/utils/dates/getLiveDuration";
import capitalize from "src/shared/utils/strings/capitalize";
import invariant from "tiny-invariant";

export default function PricePlanSummaryMetrics() {
  const [type, setType] = useState<"FORECAST" | "IMPACT">("FORECAST");
  const impactFilter = usePricePlanStore((state) => state.impactViewFilter);
  const customerSegment = usePricePlanStore((state) => state.customerSegment);
  const [publishDraftResultsModalIsOpen, setPublishDraftResultsModalIsOpen] =
    useState(false);

  const { groupId } = useParams();

  // TODO: we should create one query for this that accepts a pricePlanId and an optional groupId
  const groupPricePlanMetrics = useGetGroupSummaryQuery();
  const allPricePlanMetrics = useGetPricePlanSummaryQuery();
  const brandLevelDisabled = groupId != null && groupId !== "all";
  const pricePlanMetrics = brandLevelDisabled
    ? groupPricePlanMetrics
    : allPricePlanMetrics;

  const {
    group_results_metrics: results,
    opportunity_metrics: opportunity,
    price_plan: pricePlan,
  } = pricePlanMetrics?.data ?? {};

  const opportunitySegment = (opportunity ?? []).find(
    (val) => val.customer_segment === customerSegment,
  );

  const resultsSegment = (results ?? []).find(
    (val) => val.customer_segment === customerSegment,
  );

  const availableCustomerSegments = useMemo(() => {
    const customerSegments = new Set<CustomerSegmentType>();
    for (const { customer_segment } of opportunity ?? []) {
      if (customer_segment != null) {
        customerSegments.add(customer_segment);
      }
    }
    return customerSegments;
  }, [opportunity]);

  const hasCustomerSegmentsAvailable = availableCustomerSegments.size > 0;

  useEffect(() => {
    if (opportunity == null || opportunity.length === 0) {
      return;
    }

    const defaultSegment = opportunity[0].customer_segment;
    if (customerSegment == null) {
      if (hasCustomerSegmentsAvailable) {
        usePricePlanStore.setState({
          customerSegment: defaultSegment,
        });
      }
    } else if (!availableCustomerSegments.has(customerSegment)) {
      usePricePlanStore.setState({
        customerSegment: defaultSegment,
      });
    }
  }, [
    availableCustomerSegments,
    customerSegment,
    hasCustomerSegmentsAvailable,
    opportunity,
  ]);

  if (pricePlanMetrics.isError) {
    return (
      <Card className="p-6">
        <p>Failed to load price plan metrics...</p>
        <p>{pricePlanMetrics.error?.message}</p>
      </Card>
    );
  }

  if (pricePlanMetrics.isLoading) {
    return <Skeleton className="h-24 w-full" />;
  }

  if (pricePlanMetrics?.data == null) {
    return (
      <Card className="p-6">
        <p>No price plan found.</p>
      </Card>
    );
  }

  let subtitle = "";
  if (type === "FORECAST") {
    const pricePlanPublishedAt =
      pricePlan?.created_at == null ? null : formatDate(pricePlan?.created_at);
    const forecastRefreshedLabel =
      pricePlanPublishedAt == null
        ? null
        : `, refreshed on ${pricePlanPublishedAt}`;

    if (opportunitySegment == null) {
      if (pricePlan?.generation_in_progress) {
        subtitle = "Forecast is still being generated";
      } else {
        subtitle = "No forecast data available";
      }
    } else {
      subtitle = `30 day projections${forecastRefreshedLabel}`;
    }
  }

  if (type === "IMPACT") {
    const resultsRefreshedAt =
      resultsSegment?.created_at == null
        ? null
        : formatDate(resultsSegment?.created_at);
    const { daysLiveLabel: resultsDaysLiveLabel } = getLiveDuration(
      pricePlan?.start_time,
      resultsSegment?.created_at,
    );
    const impactRefreshedLabel =
      resultsRefreshedAt == null
        ? null
        : `, refreshed on ${resultsRefreshedAt}`;
    const hasGroupResults = resultsSegment != null;

    subtitle = hasGroupResults
      ? `${resultsDaysLiveLabel} day impact${impactRefreshedLabel}`
      : "Insufficient data to measure impact";
  }

  const isDraft = resultsSegment?.is_draft;

  return (
    <>
      <Col className="w-full gap-4">
        <Row className="w-full content-between justify-between">
          <Row className="gap-2">
            <p className="font-bold">{capitalize(type.toLowerCase())}</p>
            <p className="text-sm italic text-n-500">{subtitle}</p>
          </Row>
          <Row className="gap-2">
            {isDraft && (
              <Badge
                className="hover:cursor-pointer"
                onClick={() => setPublishDraftResultsModalIsOpen(true)}
                variant="warning"
              >
                DRAFT RESULTS
              </Badge>
            )}
            <div>
              <SimpleSelect
                onValueChange={(newValue) => {
                  if (newValue === "FORECAST" || newValue === "IMPACT") {
                    setType(newValue);
                  } else {
                    invariant(
                      false,
                      "Unexpected value for type. Expected 'FORECAST' or 'IMPACT'.",
                    );
                  }
                }}
                options={[
                  { label: "Forecast", value: "FORECAST" },
                  { label: "Impact", value: "IMPACT" },
                ]}
                placeholder="View Type"
                value={type}
              />
            </div>
            <div>
              <SimpleSelect
                onValueChange={(impactViewFilter) => {
                  if (
                    impactViewFilter === "brand-level" ||
                    impactViewFilter === "price-plan-level"
                  ) {
                    usePricePlanStore.setState({ impactViewFilter });
                  } else {
                    invariant(false, "Unexpected value for impactViewFilter.");
                  }
                }}
                options={[
                  {
                    label: "Price Plan Level Metrics",
                    value: "price-plan-level",
                  },
                  {
                    disabled: brandLevelDisabled,
                    label: "Brand Level Metrics",
                    value: "brand-level",
                  },
                ]}
                placeholder="Metrics Level"
                value={impactFilter}
              />
            </div>
            {Boolean(hasCustomerSegmentsAvailable) ? (
              <div>
                <SimpleSelect
                  onValueChange={(newCustomerSegment) => {
                    if (
                      CustomerSegmentVariants.includes(
                        newCustomerSegment as CustomerSegmentType,
                      )
                    ) {
                      usePricePlanStore.setState({
                        customerSegment:
                          newCustomerSegment as CustomerSegmentType,
                      });
                    } else {
                      invariant(
                        false,
                        "Unexpected value for member segment filter.",
                      );
                    }
                  }}
                  options={CustomerSegmentVariants.map((segment) => {
                    const disabled = !availableCustomerSegments.has(segment);
                    return {
                      disabled,
                      label: `${formatGenericEnumLabel(segment)} Segment`,
                      value: segment,
                    };
                  })}
                  placeholder="Customer Segments"
                  value={customerSegment as string}
                />
              </div>
            ) : null}
          </Row>
        </Row>
        <PricePlanAdminWrapper>
          <MetricSummaryCards
            opportunitySegment={opportunitySegment}
            resultsSegment={resultsSegment}
            type={type}
          />
        </PricePlanAdminWrapper>
      </Col>
      {isDraft ? (
        <PublishDraftResultsModal
          isModalOpen={publishDraftResultsModalIsOpen}
          setIsModalOpen={setPublishDraftResultsModalIsOpen}
        />
      ) : null}
    </>
  );
}
