import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";
import { Maybe } from "src/shared/types/maybe/Maybe";

type SummaryMetric = {
  confidence: {
    lowerBound: Maybe<number>;
    upperBound: Maybe<number>;
  };
  impact: Maybe<number>;
  impactPercent: Maybe<number>;
};

type SummaryMetricViewType = {
  margin: SummaryMetric;
  profit: SummaryMetric;
  revenue: SummaryMetric;
  units: SummaryMetric;
};

type SummaryMetricMap = {
  forecast: {
    brandLevel: SummaryMetricViewType;
    pricePlanLevel: SummaryMetricViewType;
  };
  impact: {
    brandLevel: SummaryMetricViewType;
    pricePlanLevel: SummaryMetricViewType;
  };
};

export default function mapMetricData(
  opportunitySegment?: GetPricePlanSummaryQueryResponseType["opportunity_metrics"][number],
  resultsSegment?: GetPricePlanSummaryQueryResponseType["group_results_metrics"][number],
): SummaryMetricMap {
  const forecastMarginDifference =
    opportunitySegment?.treatment_price_margin &&
    opportunitySegment.brand_new_margin
      ? opportunitySegment.treatment_price_margin -
        opportunitySegment.brand_new_margin
      : 0;

  const resultsMarginDifference =
    resultsSegment?.treatment_price_margin &&
    resultsSegment?.brand_treatment_margin
      ? resultsSegment.treatment_price_margin -
        resultsSegment.brand_treatment_margin
      : 0;

  return {
    forecast: {
      brandLevel: {
        margin: {
          confidence: {
            lowerBound:
              opportunitySegment?.new_margin_lower_bound != null
                ? opportunitySegment.new_margin_lower_bound -
                  forecastMarginDifference
                : null,
            upperBound:
              opportunitySegment?.new_margin_upper_bound != null
                ? opportunitySegment.new_margin_upper_bound -
                  forecastMarginDifference
                : null,
          },
          impact: opportunitySegment?.brand_new_margin,
          impactPercent: opportunitySegment?.brand_margin_impact_percent,
        },
        profit: {
          confidence: {
            lowerBound: opportunitySegment?.new_profit_lower_bound,
            upperBound: opportunitySegment?.new_profit_upper_bound,
          },
          impact: opportunitySegment?.brand_profit_change_projected,
          impactPercent:
            opportunitySegment?.brand_profit_change_percent_projected,
        },
        revenue: {
          confidence: {
            lowerBound: opportunitySegment?.new_revenue_lower_bound,
            upperBound: opportunitySegment?.new_revenue_upper_bound,
          },
          impact: opportunitySegment?.brand_revenue_change_projected,
          impactPercent:
            opportunitySegment?.brand_revenue_change_percent_projected,
        },
        units: {
          confidence: {
            lowerBound: opportunitySegment?.new_quantity_lower_bound,
            upperBound: opportunitySegment?.new_quantity_upper_bound,
          },
          impact: opportunitySegment?.brand_units_sold_change_projected,
          impactPercent:
            opportunitySegment?.brand_units_sold_change_percent_projected,
        },
      },
      pricePlanLevel: {
        margin: {
          confidence: {
            lowerBound: opportunitySegment?.new_margin_lower_bound,
            upperBound: opportunitySegment?.new_margin_upper_bound,
          },
          impact: opportunitySegment?.treatment_price_margin,
          impactPercent: opportunitySegment?.margin_impact_percent,
        },
        profit: {
          confidence: {
            lowerBound: opportunitySegment?.new_profit_lower_bound,
            upperBound: opportunitySegment?.new_profit_upper_bound,
          },
          impact: opportunitySegment?.profit_change_projected,
          impactPercent: opportunitySegment?.profit_change_percent_projected,
        },
        revenue: {
          confidence: {
            lowerBound: opportunitySegment?.new_revenue_lower_bound,
            upperBound: opportunitySegment?.new_revenue_upper_bound,
          },
          impact: opportunitySegment?.revenue_change_projected,
          impactPercent: opportunitySegment?.revenue_change_percent_projected,
        },
        units: {
          confidence: {
            lowerBound: opportunitySegment?.new_quantity_lower_bound,
            upperBound: opportunitySegment?.new_quantity_upper_bound,
          },
          impact: opportunitySegment?.units_sold_change_projected,
          impactPercent:
            opportunitySegment?.units_sold_change_percent_projected,
        },
      },
    },
    impact: {
      brandLevel: {
        margin: {
          confidence: {
            lowerBound:
              resultsSegment?.margin_lower_bound != null
                ? resultsSegment.margin_lower_bound - resultsMarginDifference
                : null,
            upperBound:
              resultsSegment?.margin_upper_bound != null
                ? resultsSegment.margin_upper_bound - resultsMarginDifference
                : null,
          },
          impact: resultsSegment?.brand_treatment_margin,
          impactPercent: resultsSegment?.brand_margin_change_percent,
        },
        profit: {
          confidence: {
            lowerBound: resultsSegment?.profit_lower_bound,
            upperBound: resultsSegment?.profit_upper_bound,
          },
          impact: resultsSegment?.brand_profit_change,
          impactPercent: resultsSegment?.brand_profit_change_percent,
        },
        revenue: {
          confidence: {
            lowerBound: resultsSegment?.revenue_lower_bound,
            upperBound: resultsSegment?.revenue_upper_bound,
          },
          impact: resultsSegment?.brand_revenue_change,
          impactPercent: resultsSegment?.brand_revenue_change_percent,
        },
        units: {
          confidence: {
            lowerBound: resultsSegment?.units_sold_lower_bound,
            upperBound: resultsSegment?.units_sold_upper_bound,
          },
          impact: resultsSegment?.brand_units_sold_change,
          impactPercent: resultsSegment?.brand_units_sold_change_percent,
        },
      },
      pricePlanLevel: {
        margin: {
          confidence: {
            lowerBound: resultsSegment?.margin_lower_bound,
            upperBound: resultsSegment?.margin_upper_bound,
          },
          impact: resultsSegment?.treatment_price_margin,
          impactPercent: resultsSegment?.margin_change_percent,
        },
        profit: {
          confidence: {
            lowerBound: resultsSegment?.profit_lower_bound,
            upperBound: resultsSegment?.profit_upper_bound,
          },
          impact: resultsSegment?.profit_change,
          impactPercent: resultsSegment?.profit_change_percent,
        },
        revenue: {
          confidence: {
            lowerBound: resultsSegment?.revenue_lower_bound,
            upperBound: resultsSegment?.revenue_upper_bound,
          },
          impact: resultsSegment?.revenue_change,
          impactPercent: resultsSegment?.revenue_change_percent,
        },
        units: {
          confidence: {
            lowerBound: resultsSegment?.units_sold_lower_bound,
            upperBound: resultsSegment?.units_sold_upper_bound,
          },
          impact: resultsSegment?.units_sold_change,
          impactPercent: resultsSegment?.units_sold_change_percent,
        },
      },
    },
  };
}
