import { Rocket } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import apiClient from "src/frontend/api/ApiClient";
import TrpcClient from "src/frontend/api/TrpcClient";
import LinkText from "src/frontend/components/LinkText";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import usePricePlanGroupsQuery from "src/frontend/hooks/queries/usePricePlanGroupsQuery";
import useBrandId from "src/frontend/hooks/useBrandId";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import downloadNewPricePlanCsv from "src/frontend/utils/download/downloadNewPricePlanCsv";
import invariant from "tiny-invariant";

function Step({ children }: { children: React.ReactNode }) {
  return <p className="mt-4 text-lg font-bold">{children}</p>;
}

export default function ConfirmPriceChangesModal() {
  const pricePlanGroupsQuery = usePricePlanGroupsQuery();
  const brandId = useBrandId();
  const { customerSegment, pricePlanId } = usePricePlanStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedPrices, setUploadedPrices] = useState(false);
  const [downloadedPricePlan, setDownloadedPricePlan] = useState(false);
  const isBrandAdmin = useIsBrandAdmin();
  const t = useToast();
  const navigate = useNavigate();
  const sendSlackProductNotificationMutation =
    TrpcClient.internal.sendSlackProductNotification.useMutation();
  const approvePricePlanMutation =
    TrpcClient.internal.approvePricePlan.useMutation();

  useEffect(() => {
    if (!isModalOpen) {
      setDownloadedPricePlan(false);
      setUploadedPrices(false);
    }
  }, [downloadedPricePlan, uploadedPrices, isModalOpen]);

  const downloadPricePlan = async () => {
    if (pricePlanGroupsQuery.data != null) {
      t.infoToast("Starting price plan download...");
      try {
        const result = await apiClient.downloadResultsPricePlanCsv({
          brandId,
          customerSegment,
          groupIds: [],
          pricePlanId: pricePlanId ?? null,
        });
        downloadNewPricePlanCsv(result, customerSegment, {
          skipGroups: true,
          skipPricePlan: true,
        });
        t.successToast("Report downloaded successfully!");
      } catch (err: any) {
        t.errorToast("There was an error generating the report.");
      }
    }
  };

  return (
    <Modal
      cancelText="Cancel"
      confirmButtonDisabled={!uploadedPrices}
      confirmText="Confirm Launch"
      dialogDescription={
        <div>
          <Step>Step 1</Step>
          <p>
            <LinkText
              onClick={() => {
                void downloadPricePlan();
                setDownloadedPricePlan(true);
                sendSlackProductNotificationMutation.mutate({
                  message:
                    "❗ User downloaded prices for a manual confirm price plan. Hopefully they will confirm their price update shortly.",
                });
              }}
            >
              Download Price Plan
            </LinkText>
          </p>
          <Step>Step 2</Step>
          <p>Upload prices to your system</p>
          <Checkbox
            checked={uploadedPrices}
            disabled={!downloadedPricePlan}
            id="uploaded-price-plan"
            label="I confirm I have uploaded the prices and they are live"
            onCheckedChange={() => setUploadedPrices(true)}
          />
        </div>
      }
      dialogTitle="Confirm Price Updates"
      loading={approvePricePlanMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null, "pricePlanId cannot be null.");
        approvePricePlanMutation.mutate(
          {
            pricePlanId,
            shouldPushPrices: false,
          },
          {
            onError: () => {
              t.errorToast("Failed to launch price plan.");
            },
            onSuccess: () => {
              t.successToast("Price plan launched successfully!");
              navigate("/pricing/current", { replace: true });
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button disabled={pricePlanId == null || !isBrandAdmin}>
          <Rocket className="mr-4" size={20} strokeWidth={3} />
          <h2 className="text-xl font-bold">Confirm Price Updates</h2>
        </Button>
      }
    />
  );
}
