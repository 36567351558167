import {
  ChevronLeft,
  ChevronRight,
  PlusIcon,
  SaveIcon,
  Trash2Icon,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TrpcClient from "src/frontend/api/TrpcClient";
import PageContainer from "src/frontend/components/PageContainer";
import Row from "src/frontend/components/Row";
import UserColumnVisibilityFilterComponentV2 from "src/frontend/components/UserColumnVisibilityFilterComponentV2";
import CustomizedFilter, {
  BothFilters,
  ColumnFilterOption,
  NumberFilters,
  RuleTypeFilters,
  TextFilters,
} from "src/frontend/components/design-system/CustomizedFilter";
import SearchInputV2 from "src/frontend/components/design-system/SearchInputV2";
import { FilterIcon } from "src/frontend/components/icons";
import DeclineGroupModal from "src/frontend/components/modals/DeclineGroupModal";
import RevertGroupModal from "src/frontend/components/modals/RevertGroupModal";
import UndoDeclinedExperimentsModal from "src/frontend/components/modals/UndoDeclinedExperimentsModal";
import TableSkeleton from "src/frontend/components/skeletons/TableSkeleton";
import Button from "src/frontend/components/ui/Button";
import { Prompt } from "src/frontend/components/ui/Prompt";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useToast from "src/frontend/components/ui/useToast";
import useGetExperimentsForecastQuery from "src/frontend/hooks/queries/useGetExperimentsForecastQuery";
import useGetGroupSummaryQuery from "src/frontend/hooks/queries/useGetGroupSummaryQuery";
import useUserColumnVisibilityFiltersQuery from "src/frontend/hooks/queries/useUserColumnVisibilityFiltersQuery";
import useBrandId from "src/frontend/hooks/useBrandId";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import useScrollToPageContainerTop from "src/frontend/hooks/useScrollToPageContainerTop";
import { CONSTRAINT_TYPE_OPTIONS } from "src/frontend/pages/company/rules/ConstraintDialog";
import PricePlanSummaryMetrics from "src/frontend/pages/pricing/PricePlanSummaryMetrics/PricePlanSummaryMetrics";
import ForecastTable from "src/frontend/pages/pricing/details/ForecastTableV2";
import useAdminDeleteGroupStore from "src/frontend/stores/useAdminDeleteGroupStore";
import useEditPricePlanStore from "src/frontend/stores/useEditPricePlanStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { VisibilityFiltersUnion } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import {
  ExperimentFilterColumnEnum,
  ExperimentFilterColumnFilterEnum,
  FilterTypesEnumType,
} from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import len from "src/shared/utils/arrays/len";
import pluralize from "src/shared/utils/strings/pluralize";

export type ExperimentFilter = {
  columnFilterValue: ExperimentFilterColumnEnum;
  selectedFilter: ExperimentFilterColumnFilterEnum;
  textFilterOptions?: string[];
  textFilterValue?: string;
  type: FilterTypesEnumType;
};

const NewFilter = {
  columnFilterValue: "brand",
  selectedFilter: "is",
  type: "string",
};

const PricePlanGroupConfigMap = new Map([
  [
    "SKU Definition",
    [
      "brand",
      "category",
      "sub_category",
      "product_type",
      { filter: "kvi", label: "KVI" },
      "price_zone",
      "supplier",
      "producer",
      // ? Not a number field in the database yet.
      // "weight",
      "weight_unit",
    ],
  ],
  ["Strategy", ["elasticity", "member_elasticity"]],
  ["Rules", ["rules_met"]],
  [
    "Pricing information",
    [
      "new_list_price",
      "previous_list_price",
      "new_member_price",
      "previous_member_price",
    ],
  ],
  ["Margin Information", ["cost"]],
  [
    "Last 30 Days",
    [
      { filter: "revenue_30d", label: "30D Revenue" },
      { filter: "units_sold_30d", label: "30D Units Sold" },
      { filter: "profit_30d", label: "30D Profit" },
      { filter: "revenue_rank_30d", label: "30D Revenue Rank" },
      { filter: "units_sold_rank_30d", label: "30D Units Sold Rank" },
      { filter: "profit_rank_30d", label: "30D Profit Rank" },
      { filter: "revenue_60d", label: "60D Revenue" },
      { filter: "units_sold_60d", label: "60D Units Sold" },
      { filter: "profit_60d", label: "60D Profit" },
      { filter: "revenue_rank_60d", label: "60D Revenue Rank" },
      { filter: "units_sold_rank_60d", label: "60D Units Sold Rank" },
      { filter: "profit_rank_60d", label: "60D Profit Rank" },
      { filter: "revenue_90d", label: "90D Revenue" },
      { filter: "units_sold_90d", label: "90D Units Sold" },
      { filter: "profit_90d", label: "90D Profit" },
      { filter: "revenue_rank_90d", label: "90D Revenue Rank" },
      { filter: "units_sold_rank_90d", label: "90D Units Sold Rank" },
      { filter: "profit_rank_90d", label: "90D Profit Rank" },
      { filter: "revenue_120d", label: "120D Revenue" },
      { filter: "units_sold_120d", label: "120D Units Sold" },
      { filter: "profit_120d", label: "120D Profit" },
      { filter: "revenue_rank_120d", label: "120D Revenue Rank" },
      { filter: "units_sold_rank_120d", label: "120D Units Sold Rank" },
      { filter: "profit_rank_120d", label: "120D Profit Rank" },
    ],
  ],
]);

function getColumnFilterOptions(): ColumnFilterOption[] {
  const ColumnFilterOptionsArray: ColumnFilterOption[] = [];
  for (const [label, options] of PricePlanGroupConfigMap) {
    ColumnFilterOptionsArray.push({ label, type: "label" });

    for (const option of options) {
      if (typeof option === "string") {
        ColumnFilterOptionsArray.push({
          label: formatGenericEnumLabel(option),
          type: "item",
          value: option as keyof VisibilityFiltersUnion,
        });
      } else {
        ColumnFilterOptionsArray.push({
          label: option.label,
          type: "item",
          value: option.filter as keyof VisibilityFiltersUnion,
        });
      }
    }
  }
  return ColumnFilterOptionsArray;
}

export default function PricePlanGroupDetail() {
  useScrollToPageContainerTop();
  const t = useToast();
  const tab = usePricePlanTab();
  const brandId = useBrandId();
  const { groupId, pricePlanId } = useParams();

  const priceEditStore = useEditPricePlanStore();
  const [editPricePlanPromptOpen, setEditPricePlanPromptOpen] = useState(false);
  const [priceEditLoading, setPriceEditLoading] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const overflowListRef = useRef<HTMLDivElement>(null);

  const ColumnFilterOptions: ColumnFilterOption[] = getColumnFilterOptions();

  const checkOverflow = () => {
    if (overflowListRef?.current && listContainerRef?.current) {
      const { clientWidth, scrollLeft, scrollWidth } = overflowListRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    const current = overflowListRef.current;
    window.addEventListener("resize", checkOverflow);
    current?.addEventListener("scroll", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      current?.removeEventListener("scroll", checkOverflow);
    };
  }, [overflowListRef]);

  const scrollLeft = () => {
    if (overflowListRef.current) {
      overflowListRef.current.scrollBy({
        behavior: "smooth",
        left: -260,
      });
      checkOverflow();
    }
  };

  const scrollRight = () => {
    if (overflowListRef.current) {
      overflowListRef.current.scrollBy({
        behavior: "smooth",
        left: 260,
      });
      checkOverflow();
    }
  };

  const [searchParams, setSearchParam] = useSearchParams();
  const searchQuery = searchParams.get("searchQuery") ?? "";

  const filters = useMemo(
    () =>
      searchParams?.get("filters")?.length
        ? (JSON.parse(searchParams.get("filters")!) as ExperimentFilter[])
        : [],
    [searchParams],
  );

  useEffect(() => {
    checkOverflow();
  }, [filters]);

  const productCategoriesQuery =
    TrpcClient.internal.getBrandProductCategories.useQuery({ brandId });

  const [isAllExperimentsSelected, setIsAllExperimentsSelected] =
    useState(false);
  const [selectedExperiments, setSelectedExperiments] = useState<Set<string>>(
    new Set(),
  );
  const textFilterOptions: { [key in ExperimentFilterColumnEnum]?: string[] } =
    {
      brand: productCategoriesQuery.data?.productBrandNames ?? [],
      category: productCategoriesQuery.data?.categories ?? [],
      elasticity: ["LOW", "MEDIUM", "HIGH"],
      kvi: [],
      member_elasticity: ["LOW", "MEDIUM", "HIGH"],
      price_zone: productCategoriesQuery.data?.priceZones ?? [],
      producer: [],
      product_type: [],
      rules_met: CONSTRAINT_TYPE_OPTIONS.filter(
        (val) => val !== "ROUNDING" && val !== "AVERAGE_MARGIN",
      ),
      sub_category: productCategoriesQuery.data?.subCategories ?? [],
      supplier: productCategoriesQuery.data?.suppliers ?? [],
      weight_unit: [],
    };

  const visibilityFiltersQuery = useUserColumnVisibilityFiltersQuery();
  const getGroupSummaryQuery = useGetGroupSummaryQuery();
  const experimentsForecastQuery = useGetExperimentsForecastQuery();

  const experiments = useMemo(
    () =>
      experimentsForecastQuery.data?.pages.map((page) => page.items).flat() ??
      [],
    [experimentsForecastQuery.data],
  );

  useEffect(() => {
    if (selectedExperiments.size > 0) {
      const experimentIds = experiments.map((exp) => exp.experiment_id);
      if (selectedExperiments.isSubsetOf(new Set(experimentIds)) === false) {
        setSelectedExperiments(new Set());
        setIsAllExperimentsSelected(false);
      }
    }
  }, [experiments, selectedExperiments]);

  const editPricePlanExperimentsMutation =
    TrpcClient.internal.editPricePlanExperiments.useMutation();
  const { group_title: groupTitle } = getGroupSummaryQuery.data ?? {};
  const groupExists = groupTitle != null || groupId === "all";

  if (pricePlanId == null || groupId == null) {
    return "No group found.";
  }

  const handleSelectAllExperiments = (checked: boolean) => {
    setIsAllExperimentsSelected(checked);
    if (checked === false) {
      setSelectedExperiments(new Set());
    }
  };

  const resetSelectedExperiments = () => {
    setSelectedExperiments(new Set());
    setIsAllExperimentsSelected(false);
  };

  const handleSelectExperiment = (experimentId: string, checked: boolean) => {
    if (checked) {
      setSelectedExperiments(new Set(selectedExperiments).add(experimentId));
    } else {
      const newSelection = new Set(selectedExperiments);
      newSelection.delete(experimentId);
      setSelectedExperiments(newSelection);
    }
  };

  const onEditSuccess = async () => {
    const response = await experimentsForecastQuery.refetch();
    const experiments =
      response.data?.pages.map((page) => page.items).flat() ?? [];
    priceEditStore.setInitialState(experiments, groupId);
    t.successToast("Price plan edited successfully.");
    setPriceEditLoading(false);
    setEditPricePlanPromptOpen(false);
  };

  const handleEditPricePlan = () => {
    t.infoToast("Saving price edits...");
    setPriceEditLoading(true);
    const edits = priceEditStore.getEditedPrices(experiments);
    editPricePlanExperimentsMutation.mutate(
      {
        edits,
      },
      {
        onError: () => {
          t.errorToast("Failed to edit price plan.");
          setPriceEditLoading(false);
          setEditPricePlanPromptOpen(false);
        },
        onSuccess: () => {
          void onEditSuccess();
        },
      },
    );
  };

  const priceEdits = priceEditStore.getEditedPrices(experiments ?? []);
  const hasPriceEdits = arrayNotEmpty(priceEdits);
  const priceEditCount = len(priceEdits);

  const handleSearchQueryChange = (val: string) => {
    if (val === "") {
      searchParams.delete("searchQuery");
    } else {
      searchParams.set("searchQuery", val);
    }
    setSearchParam(searchParams);
  };

  const handleFilterClick = () => {
    if (filters.length > 0) {
      searchParams.delete("filters");
      setSearchParam(searchParams);
    } else {
      const filters = [NewFilter];
      searchParams.set("filters", JSON.stringify(filters));
      setSearchParam(searchParams);
    }
  };

  const handleAddFilterClick = () => {
    const newFilters = [...filters, NewFilter];
    searchParams.set("filters", JSON.stringify(newFilters));
    setSearchParam(searchParams);
    setTimeout(
      () =>
        overflowListRef.current?.scrollBy({
          left: 260,
        }),
      15,
    );
    setTimeout(checkOverflow, 15);
  };

  const filterTypeLookup: Record<FilterTypesEnumType, string[]> = {
    both: BothFilters,
    number: NumberFilters,
    rule_type: RuleTypeFilters,
    string: TextFilters,
  };

  const handleEditFilter = (index: number, filter: ExperimentFilter) => {
    const prevFilter = filters[index];

    if (prevFilter.columnFilterValue !== filter.columnFilterValue) {
      if (filter.columnFilterValue === "elasticity") {
        filter.type = "both";
      } else if (filter.columnFilterValue === "rules_met") {
        filter.type = "rule_type";
      } else if (textFilterOptions[filter.columnFilterValue] != null) {
        filter.type = "string";
      } else {
        filter.type = "number";
      }
    }
    if (prevFilter.type !== filter.type) {
      const filterLogic = filterTypeLookup[filter.type];
      filter.selectedFilter =
        filterLogic[0] as ExperimentFilterColumnFilterEnum;
      filter.textFilterValue = undefined;
    }
    delete filter.textFilterOptions;
    filters[index] = filter;
    searchParams.set("filters", JSON.stringify(filters));
    setSearchParam(searchParams);
  };

  const handleDeleteFilter = (index: number) => {
    filters.splice(index, 1);
    searchParams.set("filters", JSON.stringify(filters));
    setSearchParam(searchParams);
  };

  return (
    <PageContainer className="overflow-y-visible pt-0">
      <Row className="w-full pb-4">
        <PricePlanSummaryMetrics />
      </Row>
      {getGroupSummaryQuery.isLoading ? (
        <Skeleton className="mb-3 h-12 w-1/4 rounded-xl" />
      ) : getGroupSummaryQuery.isError ? (
        <div>
          <p>Failed to load group summary.</p>
        </div>
      ) : !groupExists ? null : (
        <Row className="justify-end gap-2">
          {tab === "current" && (
            <RevertGroupModal
              allExperimentsSelected={isAllExperimentsSelected}
              experiment_ids={selectedExperiments}
              groupId={groupId}
              resetSelection={resetSelectedExperiments}
            />
          )}
          {tab != null &&
            ["preview", "draft", "new", "internal"].includes(tab) &&
            hasPriceEdits && (
              <Prompt
                confirmText="Save Edits"
                description={
                  <p>
                    Save edits to {priceEditCount}{" "}
                    {pluralize("product", priceEditCount)}? You can always edit
                    the prices again before approving the price plan.
                  </p>
                }
                loading={priceEditLoading}
                onCancel={() => setEditPricePlanPromptOpen(false)}
                onConfirm={handleEditPricePlan}
                open={editPricePlanPromptOpen}
                title="Save Edits"
                trigger={
                  <Button
                    disabled={!hasPriceEdits}
                    onClick={() => {
                      setEditPricePlanPromptOpen(true);
                    }}
                    size="sm"
                    variant="basic"
                  >
                    <SaveIcon className="mr-1" size={16} /> Save edits
                  </Button>
                }
              />
            )}
          {(tab === "draft" || tab === "preview" || tab === "internal") && (
            <UndoDeclinedExperimentsModal />
          )}
          {(tab === "draft" || tab === "preview" || tab === "internal") &&
            (isAllExperimentsSelected || selectedExperiments.size > 0) && (
              <DeclineGroupModal
                experiment_ids={selectedExperiments}
                isAllExperimentsSelected={isAllExperimentsSelected}
                resetSelectedExperiments={resetSelectedExperiments}
              />
            )}
          {tab === "internal" && (
            <Button
              onClick={() => {
                useAdminDeleteGroupStore.setState(
                  selectedExperiments.size > 0
                    ? {
                        experimentIds: Array.from(selectedExperiments),
                        isModalOpen: true,
                      }
                    : {
                        groupIds: [groupId],
                        isModalOpen: true,
                      },
                );
              }}
              size="sm"
              variant="outline"
            >
              <Trash2Icon className="mr-2" size={16} />{" "}
              {selectedExperiments.size === 0
                ? "Delete Group"
                : "Delete Selected Experiments"}
            </Button>
          )}
        </Row>
      )}
      <Row>
        <h1 className=" text-lg font-bold ">{groupTitle ?? "Product List"}</h1>
      </Row>
      <Row className="relative my-4 justify-between">
        <div className="flex gap-4">
          <SearchInputV2
            className="w-[241px]"
            onChange={handleSearchQueryChange}
            placeholder="Search by Product or SKU"
            value={searchQuery}
          />
          <Button
            className="flex gap-1"
            onClick={handleFilterClick}
            variant="basic"
          >
            Filter{filters.length > 0 && `(${filters.length})`}{" "}
            {filters.length > 0 ? (
              <span className="text-zinc-400">x</span>
            ) : (
              <FilterIcon size={12} />
            )}
          </Button>
        </div>

        <UserColumnVisibilityFilterComponentV2 visibilityFilterType="price_plan" />
      </Row>
      <Row className="relative overflow-x-hidden" ref={listContainerRef}>
        {filters.length > 0 && (
          <div
            className="flex gap-2 overflow-x-auto pb-4 pt-1"
            ref={overflowListRef}
          >
            {filters.map((filter, index) => (
              <CustomizedFilter
                columnFilterOptions={ColumnFilterOptions}
                key={`${index}-${filter.columnFilterValue}`}
                {...filter}
                columnFilterValue={filter.columnFilterValue}
                onDeleteFilterClick={() => handleDeleteFilter(index)}
                onFilterChange={(filter) =>
                  handleEditFilter(index, filter as ExperimentFilter)
                }
                textFilterOptions={
                  textFilterOptions.hasOwnProperty(filter.columnFilterValue)
                    ? textFilterOptions[filter.columnFilterValue]
                    : undefined
                }
              />
            ))}
            <Button
              className="bg-neutral-100 px-2 dark:bg-neutral-800 dark:hover:bg-neutral-700"
              onClick={handleAddFilterClick}
              size="icon"
              variant="ghost"
            >
              <PlusIcon size={20} />
            </Button>
          </div>
        )}
        {canScrollLeft && (
          <div className="absolute left-0 top-0 flex h-full w-16 items-center bg-gradient-to-r from-white via-white to-transparent pb-4 pt-1  dark:from-[#2E3034] dark:via-[#2E3034]">
            <Button
              className={
                "bg-neutral-50" +
                `transition-opacity duration-300 ${
                  canScrollLeft ? "opacity-100" : "opacity-0"
                }`
              }
              onClick={scrollLeft}
              size="smIcon"
              variant="ghost"
            >
              <ChevronLeft size={20} />
            </Button>
          </div>
        )}
        {canScrollRight && (
          <div
            className={
              "absolute right-0 top-0 flex h-full w-16 items-center justify-end bg-gradient-to-l from-white via-white to-transparent pb-4 pt-1 dark:from-[#2E3034] dark:via-[#2E3034]" +
              `transition-opacity duration-300 ${
                canScrollRight ? "opacity-100" : "opacity-0"
              }`
            }
          >
            <Button
              className={
                "bg-neutral-50" +
                `transition-opacity duration-300 ${
                  canScrollRight ? "opacity-100" : "opacity-0"
                }`
              }
              onClick={scrollRight}
              size="smIcon"
              variant="ghost"
            >
              <ChevronRight size={20} />
            </Button>
          </div>
        )}
      </Row>
      {getGroupSummaryQuery.isLoading ||
      (visibilityFiltersQuery.isLoading && true) ? (
        <div className="mt-6 flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <Skeleton className="h-6 w-36" />
            <Skeleton className="h-6 w-36" />
            <Skeleton className="h-6 w-36" />
          </div>
          <TableSkeleton />
        </div>
      ) : (experimentsForecastQuery.isError ||
          getGroupSummaryQuery.isError ||
          visibilityFiltersQuery.isError) &&
        true ? (
        <div className="mt-6">
          <p>Failed to load group.</p>
        </div>
      ) : (
        // the negative margin and the "max-h-[calc(...-8rem)]" allow us to force the table to the bottom of the page
        <div className="-mb-32 block max-h-[calc(100vh-125px-8rem)] w-full overflow-visible">
          <ForecastTable
            isAllExperimentsChecked={isAllExperimentsSelected}
            isLoading={visibilityFiltersQuery.isLoading}
            onSelectAllExperiments={handleSelectAllExperiments}
            onSelectExperiment={handleSelectExperiment}
            selectedExperiments={selectedExperiments}
            visibilityFilters={visibilityFiltersQuery.data.price_plan}
          />
        </div>
      )}
    </PageContainer>
  );
}
