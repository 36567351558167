import { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BrandSalesType } from "src/backend/prisma-utils/getBrandSales";
import { PricePlanLaunchDateType } from "src/backend/prisma-utils/price-plans/getPricePlanLaunchDates";
import Row from "src/frontend/components/Row";
import CardWithContent from "src/frontend/components/ui/CardWithContent";
import Checkbox from "src/frontend/components/ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import { Separator } from "src/frontend/components/ui/Separator";
import { Tabs, TabsList, TabsTrigger } from "src/frontend/components/ui/Tabs";
import COLORS from "src/frontend/constants/Colors";
import useRechartsStyles from "src/frontend/hooks/useRechartsStyles";
import RechartsReferenceLineProps from "src/frontend/types/RechartsReferenceLineProps";
import getAppUrlForClientLink from "src/frontend/utils/getAppUrlForClientLink";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import batchArray from "src/shared/utils/arrays/batchArray";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import getFirst from "src/shared/utils/arrays/getFirst";
import getLast from "src/shared/utils/arrays/getLast";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import dayjs from "src/shared/utils/dates/dayjs";
import formatDateNonNull from "src/shared/utils/dates/formatDateNonNull";
import avg from "src/shared/utils/math/avg";
import sum from "src/shared/utils/math/sum";
import noop from "src/shared/utils/noop";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import capitalize from "src/shared/utils/strings/capitalize";
import invariant from "tiny-invariant";

const CHART_TABS = [
  "total_revenue",
  "total_units_sold",
  "total_profit",
  "total_margin",
] as const;

type SalesChartTab = (typeof CHART_TABS)[number];

const DAYS_LOOKBACK_OPTIONS = [360, 180, 90, 30] as const;

type DayLookbackOption = (typeof DAYS_LOOKBACK_OPTIONS)[number];

const CHART_AGGREGATION_OPTIONS = ["Daily", "Weekly"] as const;

type ChartAggregation = (typeof CHART_AGGREGATION_OPTIONS)[number];

function formatChartLabel(val: string): string {
  return val
    .replace("total", "")
    .split("_")
    .map((val) => capitalize(val))
    .join(" ");
}

function formatChartValue(
  value: number | string,
  tab: SalesChartTab,
  roundingPrecision = 2,
): string {
  switch (tab) {
    case "total_profit":
    case "total_revenue":
      return formatCurrency(Number(value), roundingPrecision);
    case "total_units_sold":
      return String(value);
    case "total_margin":
      return formatPercentage(Number(value));
    default:
      return assertUnreachable(tab);
  }
}

function formatDaysLookbackOption(opt: DayLookbackOption): string {
  switch (opt) {
    case 360:
      return "12 months";
    case 180:
      return "6 months";
    case 90:
      return "3 months";
    case 30:
      return "1 month";
    default:
      return assertUnreachable(opt);
  }
}

type ChartData = {
  date: string;
  dotted_total_gross_profit: MaybeNumber;
  dotted_total_margin: MaybeNumber;
  dotted_total_net_profit: MaybeNumber;
  dotted_total_profit: MaybeNumber;
  dotted_total_revenue: MaybeNumber;
  dotted_total_units_sold: MaybeNumber;
  gross_profit: number;
  margin: number;
  net_profit: number;
  profit: number;
  revenue: number;
  total_gross_profit: MaybeNumber;
  total_margin: MaybeNumber;
  total_net_profit: MaybeNumber;
  total_profit: MaybeNumber;
  total_revenue: MaybeNumber;
  total_units_sold: MaybeNumber;
  units_sold: number;
};

function convertDataToChartFormat(
  brandSales: BrandSalesType[],
  daysLookback: DayLookbackOption,
  aggregation: ChartAggregation,
): ChartData[] {
  const startDate = dayjs().subtract(daysLookback + 1, "days");
  const data = brandSales.filter((val) => dayjs(val.date).isAfter(startDate));
  switch (aggregation) {
    case "Daily":
      return data.map((val, index) => {
        const showDotted = index > data.length - 4;
        const hideSolid = index > data.length - 3;
        return {
          date: formatDateNonNull(val.date),
          dotted_total_gross_profit: showDotted ? val.total_gross_profit : null,
          dotted_total_margin: showDotted ? val.total_margin : null,
          dotted_total_net_profit: showDotted ? val.total_net_profit : null,
          dotted_total_profit: showDotted ? val.total_profit : null,
          dotted_total_revenue: showDotted ? val.total_revenue : null,
          dotted_total_units_sold: showDotted ? val.total_units_sold : null,
          gross_profit: val.total_gross_profit,
          margin: val.total_margin,
          net_profit: val.total_net_profit,
          profit: val.total_profit,
          revenue: val.total_revenue,
          total_gross_profit: hideSolid ? null : val.total_gross_profit,
          total_margin: hideSolid ? null : val.total_margin,
          total_net_profit: hideSolid ? null : val.total_net_profit,
          total_profit: hideSolid ? null : val.total_profit,
          total_revenue: hideSolid ? null : val.total_revenue,
          total_units_sold: hideSolid ? null : val.total_units_sold,
          units_sold: val.total_units_sold,
        };
      });
    case "Weekly":
      const batches = batchArray(data, 7);
      return batches.map((batch, index) => {
        const showDotted = index > batches.length - 3;
        const hideSolid = index > batches.length - 2;
        const lastEntry = getLast(batch);
        invariant(lastEntry != null);
        const total_gross_profit = sum(
          batch.map((val) => val.total_gross_profit),
        );
        const total_margin = avg(batch.map((val) => val.total_margin));
        const total_net_profit = sum(batch.map((val) => val.total_net_profit));
        const total_profit = sum(batch.map((val) => val.total_profit));
        const total_revenue = sum(batch.map((val) => val.total_revenue));
        const total_units_sold = sum(batch.map((val) => val.total_units_sold));
        return {
          date: formatDateNonNull(lastEntry.date),
          dotted_total_gross_profit: showDotted ? total_gross_profit : null,
          dotted_total_margin: showDotted ? total_margin : null,
          dotted_total_net_profit: showDotted ? total_net_profit : null,
          dotted_total_profit: showDotted ? total_profit : null,
          dotted_total_revenue: showDotted ? total_revenue : null,
          dotted_total_units_sold: showDotted ? total_units_sold : null,
          gross_profit: total_gross_profit,
          margin: total_margin,
          net_profit: total_net_profit,
          profit: total_profit,
          revenue: total_revenue,
          total_gross_profit: hideSolid ? null : total_gross_profit,
          total_margin: hideSolid ? null : total_margin,
          total_net_profit: hideSolid ? null : total_net_profit,
          total_profit: hideSolid ? null : total_profit,
          total_revenue: hideSolid ? null : total_revenue,
          total_units_sold: hideSolid ? null : total_units_sold,
          units_sold: total_units_sold,
        };
      });
    default:
      return assertUnreachable(aggregation);
  }
}

function getMetricTotal(data: ChartData[], tab: SalesChartTab): number {
  switch (tab) {
    case "total_revenue":
    case "total_units_sold":
    case "total_profit": {
      const values = filterEmptyValues(
        data.map((val) => val[tab as keyof ChartData]),
      ) as number[];
      return sum(values);
    }
    case "total_margin": {
      const values = filterEmptyValues(
        data.map((val) => val[tab as keyof ChartData]),
      ) as number[];
      return avg(values);
    }
    default:
      return assertUnreachable(tab);
  }
}

function getMetricTotalLabel(value: number, tab: SalesChartTab): string {
  switch (tab) {
    case "total_units_sold":
      return `Total ${formatChartLabel(tab)} of ${formatNumberRounded(value)}`;
    case "total_revenue":
    case "total_profit":
      return `Total ${formatChartLabel(tab)} of ${formatCurrency(value)}`;
    case "total_margin":
      return `Average ${formatChartLabel(tab)} of ${formatPercentage(value)}`;
    default:
      return assertUnreachable(tab);
  }
}

type SalesChartProps = {
  data: ChartData[];
  pricePlanLaunchDates: PricePlanLaunchDateType[];
  tab: SalesChartTab;
};

function SalesChart({ data, pricePlanLaunchDates, tab }: SalesChartProps) {
  const { axisColor, backgroundColor, fontFamily, gridColor } =
    useRechartsStyles();
  const margin = { bottom: 5, left: 5, right: 40, top: 5 };
  const largestValueLength =
    getFirst(data.map((val) => String(val[tab]).length)) ?? 0;
  return (
    <ResponsiveContainer
      height="100%"
      minHeight={285}
      minWidth={280}
      style={{ backgroundColor }}
      width="100%"
    >
      <LineChart data={data} margin={margin}>
        <defs>
          <linearGradient id="color" x1="0" x2="0" y1="0" y2="1">
            <stop offset="15%" stopColor={COLORS.PRIMARY} stopOpacity={0.95} />
            <stop offset="100%" stopColor={COLORS.PRIMARY} stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          interval="equidistantPreserveStart"
          stroke={axisColor}
          style={{ fontFamily, fontSize: 12 }}
          tickFormatter={(val) => {
            return dayjs(val).format("MM/DD/YYYY");
          }}
        />
        <YAxis
          stroke={axisColor}
          style={{ fontFamily, fontSize: 12 }}
          tickFormatter={(val) => formatChartValue(Number(val), tab, 0)}
          width={largestValueLength > 6 ? 105 : 75}
        />
        <CartesianGrid stroke={gridColor} strokeDasharray="3 3" />
        <Tooltip
          content={
            // @ts-ignore
            <CustomTooltip
              pricePlanLaunchDates={pricePlanLaunchDates}
              tab={tab}
            />
          }
        />
        {pricePlanLaunchDates.map((val) => {
          const key = formatDateNonNull(val.startTime);
          return (
            <ReferenceLine
              key={key}
              label={
                // @ts-ignore
                <PricePlanReferenceLabel
                  pricePlanId={val.pricePlanId}
                  value="Price Plan launched"
                />
              }
              stroke={COLORS.MINT}
              strokeDasharray="2 2"
              strokeWidth={1.25}
              x={key}
            />
          );
        })}
        <Line
          dataKey={tab}
          dot={false}
          isAnimationActive={false}
          stroke={COLORS.PRIMARY}
          type="monotone"
        />
        <Line
          dataKey={`dotted_${tab}`}
          dot={false}
          isAnimationActive={false}
          stroke={COLORS.PRIMARY}
          strokeDasharray="3 4 5 2"
          type="monotone"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

type RechartCustomTooltipProps = {
  active: boolean;
  label: string;
  payload: { payload: ChartData }[];
  pricePlanLaunchDates: PricePlanLaunchDateType[];
};

type CustomTooltipProps = RechartCustomTooltipProps & {
  tab: SalesChartTab;
};

const CustomTooltip = ({
  active,
  label,
  payload,
  pricePlanLaunchDates,
  tab,
}: CustomTooltipProps) => {
  const { tooltipStyle } = useRechartsStyles();
  if (active && payload?.length) {
    const isOnPricePlanLaunchDate = pricePlanLaunchDates
      .map((val) => formatDateNonNull(val.startTime))
      .some((val) => val === label);
    const key = `dotted_${tab}` as keyof ChartData;
    const projectedContent = payload.find((val) => val.payload[key] != null);
    const currentPayload = projectedContent
      ? payload.find((val) => val.payload[key] != null)
      : payload.find((val) => val.payload[tab] != null);
    const value = currentPayload?.payload[projectedContent ? key : tab] ?? null;
    if (value != null) {
      return (
        <div className="rounded-sm border bg-card p-2" style={tooltipStyle}>
          <p>{label}</p>
          <p>
            {projectedContent ? "Projected " : ""}
            {formatChartLabel(tab)}: {formatChartValue(value, tab)}
          </p>
          {isOnPricePlanLaunchDate && <p>Click to view price plan.</p>}
        </div>
      );
    }
  }

  return null;
};

type PricePlanReferenceLabelProps = {
  pricePlanId: string;
};

export function PricePlanReferenceLabel(
  props: PricePlanReferenceLabelProps & RechartsReferenceLineProps,
) {
  const { pricePlanId, viewBox } = props;
  const x = viewBox.width + viewBox.x;
  const y = viewBox.y;
  const width = 36;
  const xPosition = width / 2;
  return (
    <a
      className="hover:underline"
      href={`${getAppUrlForClientLink()}/price-plan/${pricePlanId}`}
      target="_blank"
    >
      <g>
        <foreignObject height="100%" width={width} x={x - xPosition} y={y} />
      </g>
    </a>
  );
}

type BrandSalesChartProps = {
  pricePlanLaunchDates: PricePlanLaunchDateType[];
  sales: BrandSalesType[];
};

export default function BrandSalesChart({
  pricePlanLaunchDates,
  sales,
}: BrandSalesChartProps) {
  const [tab, setTab] = useState<SalesChartTab>("total_revenue");
  const [chartDaysLookback, setChartDaysLookback] =
    useState<DayLookbackOption>(30);
  const [chartAggregation, setChartAggregation] =
    useState<ChartAggregation>("Daily");

  const data = convertDataToChartFormat(
    sales,
    chartDaysLookback,
    chartAggregation,
  );

  const metricTotal = getMetricTotal(data, tab);

  const extraLabel =
    arrayEmpty(data) &&
    ` - no ${formatChartLabel(tab)
      .toLowerCase()
      .replace("total", "")} sales data exists.`;

  return (
    <CardWithContent
      cardContentClassName="p-0"
      className="flex max-w-[1250px] flex-col rounded-lg border p-0 pb-3"
    >
      <Tabs
        defaultValue={tab}
        onValueChange={(val) => setTab(val as SalesChartTab)}
        value={tab}
      >
        <TabsList className="flex border-b">
          {CHART_TABS.map((tab) => {
            return (
              <TabsTrigger
                className="flex-1 rounded-t-lg px-2 sm:px-6"
                key={tab}
                value={tab}
              >
                <p className="text-xs sm:text-base">{formatChartLabel(tab)}</p>
              </TabsTrigger>
            );
          })}
        </TabsList>
        <Row className="mt-3 justify-between pl-4 pr-10">
          <p className=" font-semibold">
            {chartAggregation} Performance ({chartDaysLookback} days)
            {extraLabel} {getMetricTotalLabel(metricTotal, tab)}
          </p>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="flex flex-row items-center justify-center hover:cursor-pointer">
                <div className="flex items-center justify-start">
                  <p className="text-sm">•••</p>
                </div>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {DAYS_LOOKBACK_OPTIONS.toReversed().map((opt) => {
                return (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    key={opt}
                    onClick={() => setChartDaysLookback(opt)}
                  >
                    <Row className="w-44 justify-between">
                      <p>View last {formatDaysLookbackOption(opt)}</p>
                      {chartDaysLookback === opt && (
                        <Checkbox
                          checked
                          id={`chart-${opt}-days-lookback-checkbox`}
                          onCheckedChange={noop}
                        />
                      )}
                    </Row>
                  </DropdownMenuItem>
                );
              })}
              <Separator className="my-1" />
              {CHART_AGGREGATION_OPTIONS.map((opt) => {
                return (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    key={opt}
                    onClick={() => setChartAggregation(opt)}
                  >
                    <Row className="w-44 justify-between">
                      <p>View {opt.toLowerCase()} metrics</p>
                      {chartAggregation === opt && (
                        <Checkbox
                          checked
                          id={`chart-${opt}-aggregation-checkbox`}
                          onCheckedChange={noop}
                        />
                      )}
                    </Row>
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </Row>
        <Row className="mb-3 mt-1 justify-between pl-4">
          <p className="text-xs">
            Vertical green lines mark price plan launch dates (only visible on
            daily chart)
          </p>
        </Row>
        <SalesChart
          data={data}
          pricePlanLaunchDates={pricePlanLaunchDates}
          tab={tab}
        />
      </Tabs>
    </CardWithContent>
  );
}
