import { IconArrowNarrowDown, IconArrowNarrowUp } from "@tabler/icons-react";
import { Maybe } from "@trpc/server";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import COLORS from "src/frontend/constants/Colors";
import HtmlDivProps from "src/frontend/types/HtmlDivProps";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrencyDelta from "src/shared/utils/formatCurrencyDelta";
import formatDelta from "src/shared/utils/formatDelta";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import roundToPrecision from "src/shared/utils/numbers/roundToPrecision";

type MetricsConfidenceInterval = {
  lowerBound: Maybe<number>;
  upperBound: Maybe<number>;
};

function getFormattedMetric(
  metric: Maybe<number | string>,
  metricType: MetricType,
): number | string {
  if (typeof metric == "number") {
    switch (metricType) {
      case "currency":
        return formatCurrencyDelta(metric, true);
      case "number":
        const result = roundToPrecision(metric, 0);
        const formatted = formatNumberRounded(result);
        return formatDelta(result, formatted) ?? "--";
      case "percent":
        return formatPercentage(metric);
      case null:
      case undefined:
        return metric ?? "--";
      default:
        return assertUnreachable(metricType);
    }
  }

  return metric ?? "--";
}

type MetricType = "currency" | "number" | "percent";

type MetricSummaryCardProps = HtmlDivProps & {
  confidenceInterval?: MetricsConfidenceInterval;
  metric?: MaybeNull<number | string>;
  metricType: MetricType;
  percentChange?: MaybeNull<number>;
  title: string;
};

export default function MetricSummaryCard({
  confidenceInterval,
  metric,
  metricType,
  percentChange,
  title,
}: MetricSummaryCardProps) {
  const value = getFormattedMetric(metric, metricType);
  return (
    <Card className="p-5">
      <Row className="justify-center">
        <Col className="items-center">
          <Row className="w-full justify-between">
            <p className="whitespace-nowrap text-sm">{title}</p>
            {percentChange != null && percentChange > 0 ? (
              <IconArrowNarrowUp
                className="rotate-45"
                color={COLORS.MINT}
                size={18}
              />
            ) : (
              <IconArrowNarrowDown
                className="rotate-45"
                color={COLORS.LUCA_RED}
                size={18}
              />
            )}
          </Row>

          <Row className="items-baseline gap-1 pb-1 pt-1.5">
            <p className="text-xl font-bold">{value}</p>
            {percentChange != null && (
              <p className="text-sm font-bold">
                ({formatSignedPercent(percentChange)})
              </p>
            )}
          </Row>

          {confidenceInterval != null ? (
            <p className="whitespace-nowrap text-xs text-muted-foreground">
              80% CI:{" "}
              {getFormattedMetric(confidenceInterval.lowerBound, metricType)},{" "}
              {getFormattedMetric(confidenceInterval.upperBound, metricType)}
            </p>
          ) : (
            <div className="h-[16px]" />
          )}
        </Col>
      </Row>
    </Card>
  );
}
