import { z } from "zod";

export const brandSettingsShape = z.object({
  competitorIntelligenceAlertsEnabled: z.boolean().optional(),
  disablePricePlanCreation: z.boolean().optional(),
});

export type BrandSettingsType = z.infer<typeof brandSettingsShape>;

export const defaultBrandSettings: BrandSettingsType = {
  competitorIntelligenceAlertsEnabled: false,
  disablePricePlanCreation: false,
};

export const allBrandSettings = z.record(z.string(), brandSettingsShape);

type LaunchDarklyFlagSet = {
  adminFeaturesEnabled: boolean;
  backgroundRefreshEnabled: boolean;
  brandSettings: z.infer<typeof allBrandSettings>;
  devFeaturesEnabled: boolean;
  lucaCommandMenuEnabled: boolean;
  maintenanceUiEnabled: boolean;
  navigationEventNotificationsEnabled: boolean;
  partialMatchSelectorEnabled: boolean;
  pricePlanAutoApproveEnabled: boolean;
  pricePlanV2Enabled: boolean;
  publicApiDocsEnabled: boolean;
};

export const LaunchDarklyDefaultFlagValues: LaunchDarklyFlagSet = {
  adminFeaturesEnabled: false,
  backgroundRefreshEnabled: false,
  brandSettings: {},
  devFeaturesEnabled: false,
  lucaCommandMenuEnabled: false,
  maintenanceUiEnabled: false,
  navigationEventNotificationsEnabled: false,
  partialMatchSelectorEnabled: false,
  pricePlanAutoApproveEnabled: false,
  pricePlanV2Enabled: false,
  publicApiDocsEnabled: false,
};

export default LaunchDarklyFlagSet;
