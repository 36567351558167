import { useState } from "react";
import { useParams } from "react-router";
import Code from "src/frontend/components/Code";
import SummaryMetricsContainer from "src/frontend/components/SummaryMetricsContainer";
import LucaContextMenu from "src/frontend/components/admin/LucaContextMenu";
import PublishInternalDraftPricePlanModal from "src/frontend/components/admin/PublishInternalDraftPricePlanModal";
import ScheduleDraftPricePlanForDeletionModal from "src/frontend/components/admin/ScheduleDraftPricePlanForDeletionModal";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import useAdminDeleteGroupStore from "src/frontend/stores/useAdminDeleteGroupStore";

export default function PricePlanAdminWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const tab = usePricePlanTab();
  const { pricePlanId } = useParams();
  const [
    isPublishPricePlanAdminModelOpen,
    setIsPublishPricePlanAdminModelOpen,
  ] = useState(false);
  const [
    isArchivePricePlanAdminModelOpen,
    setIsArchivePricePlanAdminModelOpen,
  ] = useState(false);

  if (pricePlanId == null) {
    return null;
  }

  return (
    <LucaContextMenu
      adminActions={[
        tab === "draft"
          ? {
              fn: () => {
                useAdminDeleteGroupStore.setState({
                  isModalOpen: true,
                  pricePlanIds: [pricePlanId],
                });
              },
              label: <span>Delete Price Plan</span>,
            }
          : null,
        tab === "internal"
          ? {
              fn: () => {
                setIsPublishPricePlanAdminModelOpen(true);
              },
              label: (
                <span>
                  Publish <Code>INTERNAL_DRAFT</Code> price plan
                </span>
              ),
            }
          : null,
        tab === "internal"
          ? {
              fn: () => {
                setIsArchivePricePlanAdminModelOpen(true);
              },
              label: (
                <span>
                  Schedule <Code>INTERNAL_DRAFT</Code> price plan for deletion
                </span>
              ),
            }
          : null,
      ]}
    >
      <>
        <SummaryMetricsContainer className={"flex"}>
          {children}
        </SummaryMetricsContainer>
        <PublishInternalDraftPricePlanModal
          isModalOpen={isPublishPricePlanAdminModelOpen}
          setIsModalOpen={setIsPublishPricePlanAdminModelOpen}
        />
        <ScheduleDraftPricePlanForDeletionModal
          isModalOpen={isArchivePricePlanAdminModelOpen}
          setIsModalOpen={setIsArchivePricePlanAdminModelOpen}
        />
      </>
    </LucaContextMenu>
  );
}
